

export enum ProjectRoles {
  admin = 0,
  editor = 1,
  commenter = 2,
}

export const ProjectRoleLabels = {
  [ProjectRoles.admin]: "Admin",
  [ProjectRoles.editor]: "Editor",
  [ProjectRoles.commenter]: "Commenter",
}
