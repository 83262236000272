import { useAuth } from "./auth";
import { Asset, Category, Comment, Project, Task, UserProfile, Workspace } from "./model";


export interface PermissionsProps {
  profile?: UserProfile | null,
  workspace?: Workspace | null,
  project?: Project | null,
  category?: Category | null,
  asset?: Asset | null,
  task?: Task | null,
  comment?: Comment | null,
}

export function getPermissions(props: PermissionsProps) {
  const {
    profile,
    workspace,
    project,
    category,
    asset,
    task,
    comment,
  } = props;

  const isWorkspaceAdmin = profile && workspace?.is_role("admin", profile.id) || false;
  const isWorkspaceMember = profile && workspace?.links.profile?.find(x => x.id === profile.id) || false;
  const isProjectAdmin = profile && project?.is_role("admin", profile.id) || false;
  const isProjectEditor = profile && project?.is_role("editor", profile.id) || false;

  return {

    canCreateWorkspace: isWorkspaceAdmin || false,
    canEditWorkspace: workspace && isWorkspaceAdmin || false,
    canDeleteWorkspace: workspace && isWorkspaceAdmin || false,

    canCreateProject: workspace && isWorkspaceAdmin || false,
    canEditProject: project && isWorkspaceAdmin || isProjectAdmin || false,
    canDeleteProject: project && isWorkspaceAdmin || isProjectAdmin || false,

    canCreateCategory: project && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canEditCategory: category && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canDeleteCategory: category && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,

    canCreateAsset: isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canEditAsset: asset && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canDeleteAsset: asset && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,

    canCreateTask: asset && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canEditTask: task && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canDeleteTask: task && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,

    canCreateComment: profile && isWorkspaceMember || false,
    canEditComment: profile && comment && comment.data.created_by === profile?.id || false,
    canDeleteComment: profile && comment && comment.data.created_by === profile?.id || isWorkspaceAdmin || isProjectAdmin || false,

    canCreateFile: profile && task && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canEditFile: profile && task && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,
    canDeleteFile: profile && task && isWorkspaceAdmin || isProjectAdmin || isProjectEditor || false,

  }
}


export function usePermissions(props: PermissionsProps) {

  const { profile } = useAuth();
  const workspace = Workspace.useInContext() || props.workspace;
  const project = Project.useInContext() || props.project;
  const category = Category.useInContext() || props.category;
  const asset = Asset.useInContext() || props.asset;
  const task = Task.useInContext() || props.task;
  const comment = Comment.useInContext() || props.comment;

  return getPermissions({
    profile,
    workspace,
    project,
    category,
    asset,
    task,
    comment,
  })

}
