import { Namespaces } from "./schema";
import Fuse from "fuse.js";


export const FilterOptions: Partial<{[K in keyof Namespaces]: Fuse.IFuseOptions<Namespaces[K]>}> = {
  profile: {
    keys: [
      { name: 'data.first', weight: 0.4 },
      { name: 'data.last', weight: 0.4 },
      { name: 'data.email', weight: 0.2 },
    ]
  },

  workspace: {
    keys: [
      "data.name",
    ],
  },

  project: {
    keys: [
      "data.name",
    ],
  },

  category: {
    keys: [
      "data.name",
    ],
  },

  asset: {
    keys: [
      "data.name",
    ],
  },

  task: {
    keys: [
      "data.name",
    ]
  },

  file_entry: {
    keys: [
      { name: 'data.name', weight: 0.6 },
      { name: 'data.created_by', weight: 0.2 },
    ]
  },

  comment: {
    keys: [
      'data.message',
    ]
  }

}
