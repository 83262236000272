

export function getProfileName<T extends { data: { last?: string, first?: string, email?: string } }>(profile: T | undefined | null) {
  if (!profile) return 'Unknown User';
  const name = profile.data.first
    ? profile.data.last
      ? `${profile.data.first} ${profile.data.last}`
      : profile.data.email
    : profile.data.email;
  return name || "Unknown User";
}
