

export enum WorkspaceRoles {
  member = 0,
  admin = 1,
}

export const WorkspaceRoleLabels = {
  [WorkspaceRoles.member]: "Member",
  [WorkspaceRoles.admin]: "Admin",
}