export enum TaskStatus {
  NotStarted = 0,
  NeedsWork = 1,
  InProgress = 2,
  NeedsReview = 3,
  Complete = 4,
}

export const TaskLabel = [
  'Not Started',  // 0
  'Needs Work',   // 1
  'In Progress',  // 2
  'Needs Review', // 3
  'Complete',     // 4
]

export function calculateTaskProgress<T extends { data: { status?: number | undefined } }>(tasks: T[]): number {
  const partialCredit = tasks.filter(x => x.data.status && x.data.status !== TaskStatus.Complete).length * 0.5;
  const fullCredit = tasks.filter(x => x.data.status === TaskStatus.Complete).length;
  return ((partialCredit + fullCredit) / tasks.length);
}
