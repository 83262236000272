
export const B = 1;
export const KB = B * 1000;
export const MB = KB * 1000;
export const GB = MB * 1000;
export const TB = GB * 1000;

export const STORAGE_LIMIT_ALPHA = GB * 250;
export const FILE_SIZE_LIMIT_ALPHA = GB * 100;

export enum UploadStatus {
  idle = 0,
  uploading = 1,
  succeded = 2,
  cancelled = 3,
  failed = 4,
}

/* Converts storage in bytes to a human-readable string in the form: [0-9]+\.[0-9]+\s[KMGT]?B */
export const formatStorage = (bytes: number, roundedBy: number = 2): string => {

  const roundWithoutTrailingZeroes = (num: number) => {
    return parseFloat(num.toFixed(roundedBy));
  }

  if (bytes < KB) {
    return `${bytes} B`;
  }
  else if (bytes < MB) {
    return `${roundWithoutTrailingZeroes(bytes / KB)} KB`;
  }
  else if (bytes < GB) {
    return `${roundWithoutTrailingZeroes(bytes / MB)} MB`;
  }
  else if (bytes < TB) {
    return `${roundWithoutTrailingZeroes(bytes / GB)} GB`;
  }
  else {
    return `${roundWithoutTrailingZeroes(bytes / TB)} TB`;
  }

}

// Abbreviates a filename to include the extension (required) and any other characters it can fit from the beginning of the filename.
// Example: "My really long filename.txt" => "My rea...txt"
export const abbreviateName = (str: string, maxNameLength: number = 40) => {
  const extension = str.split(".").pop() || " ";
  const name = str.substring(0, str.length - extension.length - 1);
  if (name.length > maxNameLength) {
    return name.substring(0, maxNameLength - 3) + "..." + extension;
  }
  return str;
}