import {
  TaskState,
  UploadTaskSnapshot,
  getDownloadURL,
  ref as storageRef,
  updateMetadata,
  uploadBytesResumable
} from "firebase/storage";

import { UploadStatus } from "@palette.tools/model";

import { instantDBCore, transact, id as uuid } from "./core";
import { FileEntry, Project, Workspace } from "./model";
import { storage } from "./storage";
import { delete_entity, refresh_workspace_users } from "@palette.tools/api.client";


export const uploadFile = async (
  workspace: Workspace,
  project: Project,
  file: File,
  onCreateEntry?: (fileId: string) => void,
  onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void,
  retryId?: string,
) => {
  const fileId = retryId || uuid();
  const ref = storageRef(storage, `/workspace/${workspace.id}/files/${fileId}/v1/${file.name}`);

  /* Create new File Entry and insert into task. */
  if (!retryId) {
    await transact(
      FileEntry.create({
        active: false,
        mime_type: file.type,
        id: fileId,
        name: file.name,
        path: ref.toString(), // gs://*
        size: file.size,
        status: UploadStatus.uploading,
      }, { after: (key, id) => [
        ...workspace.link(key, id),
        ...project.link(key, id),
      ] }),
    );
  }

  /* Perform upload, and call callbacks. */
  return await new Promise<FileEntry>((resolve, reject) => {
    const uploadTask = uploadBytesResumable(ref, file);
    uploadTask.on("state_changed", (snapshot: UploadTaskSnapshot) => {
      if (snapshot.state === "canceled") {
        reject(new Error("Upload canceled."));
      }
      onProgress?.(snapshot.state, snapshot.bytesTransferred, snapshot.totalBytes);
    }, async (error: Error) => {
      console.error({ error });

      if (!retryId) {
        // If it's the first attempt, refresh workspace users and retry
        console.log("Refreshing workspace users...");
        await refresh_workspace_users(workspace.id);
        try {
          const retryResult = await uploadFile(workspace, project, file, onCreateEntry, onProgress, fileId);
          resolve(retryResult);
        } catch (retryError) {
          await delete_entity("file_entry", fileId);
          reject(retryError);
        }
      } else {
        // If it's the retry attempt and it fails, reject the promise
        reject(error);
      }
    }, () => {
      onProgress?.("success", file.size, file.size);
      onCreateEntry?.(fileId);

      instantDBCore.subscribeQuery({ file_entry: { $: { where: { id: fileId } } } }, (result) => {
        const file_entry = result.data?.file_entry[0];
        if (!file_entry)
          reject(new Error("File entry wasn't created"));
        else if (file_entry.status === UploadStatus.succeded)
          resolve(FileEntry.deserialize(file_entry));
      })

      setTimeout(() => {
        reject(new Error("Timeout"));
      }, 10000);

    });

  });

}


export async function downloadFile(file_entry: FileEntry) {
  if (!file_entry.data.url) {
    return;
  }

  const a = document.createElement("a");
  a.className = 'display-none';

  const ref = storageRef(storage, file_entry.data.path);
  try {
    await updateMetadata(ref, { contentDisposition: `attachment; filename="${file_entry.data.name}"` });
  } catch (e) {
    console.error(e);
  }

  let url = file_entry.data.url;
  if (!url) {
    url = await getDownloadURL(storageRef(storage, file_entry.data.path));
    await transact(
      file_entry.update({ url }),
    );
  }

  a.href = url;
  a.download;
  a.target = '_blank';
  a.click();
  a.remove();
}
