import {
  TaskState,
  UploadTaskSnapshot,
  ref as storageRef,
  uploadBytesResumable
} from "firebase/storage";

import { UploadStatus } from "@palette.tools/model";

import { instantDBCore, transact, id as uuid } from "./core";
import { Drawover, FileEntry, Project, Workspace } from "./model";
import { storage } from "./storage";
import { delete_entity, refresh_workspace_users } from "@palette.tools/api.client";


export const uploadDrawover = async (
  workspace: Workspace,
  project: Project,
  file_entry: FileEntry,
  frame: number,
  data: string,
  onCreateEntry?: (drawoverId: string) => void,
  onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void,
  retryId?: string,
) => {

  const now = Date.now();
  const drawoverId = retryId || uuid();
  const ref = storageRef(storage, `/workspace/${workspace.id}/drawovers/${drawoverId}/${now}.json`);
  const blob = new Blob([data], { type: 'application/json' });

  /* Create new File Entry. */
  if (!retryId) {
    await transact(
      Drawover.create({
        active: false,
        frame,
        mime_type: blob.type,
        id: drawoverId,
        path: ref.toString(), // gs://*
        size: blob.size,
        status: UploadStatus.uploading,
      }, { timestamp: now, after: (key, id) => [
        ...file_entry.link(key, id, { timestamp: now }),
        ...workspace.link(key, id, { timestamp: now } ),
        ...project.link(key, id, { timestamp: now } ),
      ] }),
    );
  }

  /* Perform upload, and call callbacks. */
  return await new Promise<FileEntry>((resolve, reject) => {
    const uploadTask = uploadBytesResumable(ref, blob);
    uploadTask.on("state_changed", (snapshot: UploadTaskSnapshot) => {
      if (snapshot.state === "canceled") {
        reject(new Error("Upload canceled."));
      }
      onProgress?.(snapshot.state, snapshot.bytesTransferred, snapshot.totalBytes);
    }, async (error: Error) => {

      console.error({ error });

      if (!retryId) {

        // If it's the first attempt, refresh workspace users and retry
        console.log("Refreshing workspace users...");
        await refresh_workspace_users(workspace.id);
        try {
          const retryResult = await uploadDrawover(workspace, project, file_entry, frame, data, onCreateEntry, onProgress, drawoverId);
          resolve(retryResult);
        } catch (retryError) {
          await delete_entity("drawover", drawoverId);
          reject(retryError);
        }
      }

      else {
        // If it's the retry attempt and it fails, reject the promise
        reject(error);
      }

    }, () => {

      onProgress?.("success", blob.size, blob.size);
      onCreateEntry?.(drawoverId);

      instantDBCore.subscribeQuery({ drawover: { $: { where: { id: drawoverId } } } }, (result) => {
        const drawover = result.data?.drawover[0];
        if (!drawover)
          reject(new Error("File entry wasn't created"));
        else if (drawover.status === UploadStatus.succeded)
          resolve(FileEntry.deserialize(drawover));
      })

      setTimeout(() => {
        reject(new Error("Timeout"));
      }, 10000);

    });

  });

}


export const loadDrawover = async (drawover: Drawover) => {
  if (!drawover.data.url) {
    return null;
  }
  const response = await fetch(drawover.data.url);
  return await response.json() || {};
}
