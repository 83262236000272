import {
  Auth,
  getAuth as getAuth_Firebase,
  connectAuthEmulator as connectAuthEmulator_Firebase,
  signOut as signOut_Firebase,
} from "firebase/auth";
import {
  FirebaseApp,
  FirebaseOptions,
  initializeApp as initializeApp_Firebase,
} from "firebase/app";
import {
  FirebaseStorage,
  connectStorageEmulator as connectStorageEmulator_Firebase,
  getStorage as getStorage_Firebase,
} from "firebase/storage";


// Integration stack.
const firebaseConfig_integration: FirebaseOptions = {
  apiKey: "AIzaSyCMYH83CL2HnIWJZCJmx0ErQO6wIG4iDzE",
  authDomain: "palette-integration.firebaseapp.com",
  projectId: "palette-integration",
  storageBucket: "palette-integration.appspot.com",
  messagingSenderId: "71874744812",
  appId: "1:71874744812:web:2a2af3166d93e0d77eb0c9",
  measurementId: "G-3TPN2JXBBP"
};


// Live stack.
const firebaseConfig_storage_live = {
  apiKey: "AIzaSyDgeYOQ48neHva4m4MJjiLovwM6R2WJC5M",
  authDomain: "palette-storage-live.firebaseapp.com",
  projectId: "palette-storage-live",
  storageBucket: "palette-storage-live.appspot.com",
  messagingSenderId: "1031195207909",
  appId: "1:1031195207909:web:b92032367c2389ce430c0c",
  measurementId: "G-Z15KV2WJS4"
};


export const isNodeDev = process.env.NODE_ENV === 'development';

export const isLocalStack = (
  (
    !process.env.PALETTE_STACK // If there is no stack specified, default to local.
    || process.env.PALETTE_STACK === "local" // If the stack is explicitly set to local, use local.
  ) && (
    !process.env.NEXT_PUBLIC_PALETTE_STACK // If there is no stack specified by Next.js, default to local.
    || process.env.NEXT_PUBLIC_PALETTE_STACK === "local" // If the stack is explicitly set to local by Next.js, use local.
  )
);

export const isLiveStack = (
  (
    !isNodeDev // Cannot use the live stack if in node dev.
    && !isLocalStack // Cannot use the live stack if local.

    // Cannot use the live stack if localhost.
    && !(typeof location !== 'undefined' && location.hostname === 'localhost')

    // Cannot use the live stack if any firebase emulators are running.
    && !process.env.FIRESTORE_EMULATOR_HOST
    && !process.env.STORAGE_EMULATOR_HOST
    && !process.env.FIREBASE_EMULATOR_HUB
    && !process.env.FIREBASE_AUTH_EMULATOR_HOST

  )
  && (
    process.env.PALETTE_STACK === "live" // If the stack is explicitly set to live, use live.
    || process.env.NEXT_PUBLIC_PALETTE_STACK === "live" // If the stack is explicitly set to live by Next.js, use live.
  )
);

export const isFunctions = !!process.env.FUNCTION_TARGET || !!process.env.FUNCTIONS_EMULATOR;

export const isFirebaseAdmin = !!process.env.FIREBASE_ADMIN_PRIVATE_KEY;

/* Resolve firebase config */
let firebaseConfig: FirebaseOptions = {};
const projectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || process.env.PALETTE_FIREBASE_PROJECT_ID || process.env.FIREBASE_PROJECT_ID  || process.env.GCLOUD_PROJECT;
if (!!projectId) {
  if (projectId === firebaseConfig_storage_live.projectId) {
    firebaseConfig = firebaseConfig_storage_live;
  }
  else {
    firebaseConfig = firebaseConfig_integration;
  }
}
else if (!!process.env.FIREBASE_CONFIG) {
  const parsedConfig = JSON.parse(process.env.FIREBASE_CONFIG);
  if (parsedConfig.projectId === firebaseConfig_storage_live.projectId) {
    firebaseConfig = firebaseConfig_storage_live;
  }
  else {
    firebaseConfig = firebaseConfig_integration;
  }
}
else if (isLiveStack) {
  firebaseConfig = firebaseConfig_storage_live;
}
else {
  firebaseConfig = firebaseConfig_integration;
}
export { firebaseConfig };


function initializeApp(): FirebaseApp {
  return initializeApp_Firebase(firebaseConfig);
}

function getAuth(app: FirebaseApp): Auth {
  const auth = getAuth_Firebase(app);
  if (process.env.NEXT_PUBLIC_EMULATOR_HOST) {
    // https://stackoverflow.com/questions/73605307/firebase-auth-emulator-fails-intermittently-with-auth-emulator-config-failed
    (auth as unknown as any)._canInitEmulator = true;
    connectAuthEmulator_Firebase(auth, process.env.NEXT_PUBLIC_EMULATOR_HOST, {
      disableWarnings: true,
    });
  }
  else if (isLocalStack) {
    connectAuthEmulator_Firebase(auth, 'http://localhost:9099');
  }
  return auth;
}


function getStorage(app: FirebaseApp, bucketUrl?: string | undefined): FirebaseStorage {
  const storage = getStorage_Firebase(app, bucketUrl);
  if (isLocalStack) {
    connectStorageEmulator_Firebase(storage, 'localhost', 9199);
  }
  return storage;
}


// Initialize Firebase
export const firebaseApp = initializeApp();
export const firebaseAuth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp, `gs://${firebaseConfig.storageBucket}`);


export async function signOut() {
  await signOut_Firebase(firebaseAuth);
}
